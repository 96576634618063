import React from 'react';
import styled, { useTheme } from 'styled-components';
import { IImageWithTextCmp } from '../IImageWithTextCmp';

import {
  Button,
  ImageWithRatio,
  LinkHandler,
  Typography,
  Grid,
} from '@/components/atoms';

import { interpolation } from '@/utils/interpolation';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { mapImgWithTextColors } from '@/utils/index';

type StyleProps = {
  template?: string;
  bgColor?: string;
  borderColor?: string;
};

const Container = styled.div<StyleProps>`
  background-color: ${props => props?.bgColor};
  padding: ${props => (props?.bgColor ? '16px' : '0')};
`;

const ImagePart = styled.div<StyleProps>`
  & img {
    border-radius: ${props => props.theme.common.borderRadius};
  }
`;

const TextPart = styled.div<StyleProps>`
  padding: 0;
  ${props => props.theme.breakpoints.up('md')} {
    padding: 0 clamp(2rem, 0.9804rem + 4.183vw, 6rem) 0
      clamp(0rem, -1.5294rem + 6.2745vw, 6rem);
  }
`;

const ImageDesktop = styled.div`
  display: none;
  ${props => props.theme.breakpoints.up('md')} {
    display: block;
  }
`;
const ImageMobile = styled.div`
  display: block;
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const CustomTitle = styled(Typography)``;

const CustomTypo = styled(Typography)`
  margin-top: clamp(1.5rem, 1.5rem + 0vw, 1.5rem); //24px - 24px
`;

const ButtonContainer = styled.div`
  margin-top: clamp(1.5rem, 0.9902rem + 2.0915vw, 3.5rem); //24px - 56px
`;

const ContentBlock: React.FC<IImageWithTextCmp> = props => {
  const {
    headingTag,
    headingText,
    url,
    target,
    label,
    mediaMobile,
    media,
    template,
    desc,
    textColor,
    altTag,
    borderColor,
    ctaStyle,
    ctaColor,
    bgColor,
  } = props;

  const user = useSelector((state: RootState) => state.userReducer);
  const customerFirstName =
    user?.customer?.firstName || user?.profiling?.firstName || 'there';
  const customerCompany =
    user?.customer?.defaultAddress?.company || user?.profiling?.shopName || '';
  const theme = useTheme();
  const { textColorMapped } = mapImgWithTextColors(
    { borderColor, textColor, ctaColor },
    theme
  );

  return (
    <Container bgColor={bgColor}>
      <Grid
        container
        spacing={3}
        direction={
          template === 'content_block_img_right' ? 'row' : 'row-reverse'
        }
        alignItems="center">
        <Grid item xs={12} md={6}>
          <TextPart template={template}>
            <div>
              {headingText && (
                <CustomTitle
                  textcolor={textColorMapped}
                  component={headingTag as keyof JSX.IntrinsicElements}
                  datatype="heading_3"
                  dangerouslySetInnerHTML={{
                    __html: interpolation(headingText, {
                      customerName: `<span>${customerFirstName}</span>`,
                      storeName: `<span>${customerCompany}</span>`,
                    }),
                  }}
                />
              )}
              {desc && (
                <CustomTypo datatype="subtitle_2" textcolor={textColorMapped}>
                  {desc}
                </CustomTypo>
              )}
              {url ? (
                <ButtonContainer>
                  <LinkHandler
                    href={url}
                    passHref
                    source={`cmsLink-iwtb-${template}`}>
                    {
                      <a target={target}>
                        <Button variant={ctaStyle || 'contained'}>
                          {label}
                        </Button>
                      </a>
                    }
                  </LinkHandler>
                </ButtonContainer>
              ) : null}
            </div>
          </TextPart>
        </Grid>
        <Grid item xs={12} md={6}>
          <ImagePart template={template}>
            {media?.fields?.file?.details?.image && (
              <ImageDesktop>
                <ImageWithRatio
                  ratio={{
                    width: media?.fields?.file?.details?.image?.width,
                    height: media?.fields?.file?.details?.image?.height,
                  }}
                  media={media}
                  alt={altTag}
                />
              </ImageDesktop>
            )}
            {mediaMobile?.fields?.file?.details?.image && (
              <ImageMobile>
                <ImageWithRatio
                  ratio={{
                    width: mediaMobile?.fields?.file?.details.image.width,
                    height: mediaMobile?.fields?.file?.details.image.height,
                  }}
                  media={mediaMobile}
                  alt={altTag}
                />
              </ImageMobile>
            )}
          </ImagePart>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContentBlock;

/*      {...(ctaStyle === 'contained'
                            ? {
                                backGroundColor: ctaColorMapped,
                                color: ctaContrastColor,
                              }
                            : { color: ctaColorMapped })} */
