import MuiGrid, { GridProps } from '@material-ui/core/Grid';

interface IGrid extends GridProps {
  children: React.ReactNode;
}

const Grid: React.FC<IGrid> = ({ children, ...props }) => {
  return <MuiGrid {...props}>{children}</MuiGrid>;
};

export default Grid;
